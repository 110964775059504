.miningStatus {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #f1f1f1;
    z-index: 1;

}

.miningStatus__alert {
    text-align: center;
    color: #b4b4b4;
    opacity: 0.8;
    margin-top: 8px;
    min-height: 20px;
    transition: 0.3s all ease;
    animation: pulse 1s infinite;
    font-size: 12px;
}


@keyframes pulse {
    0% {
        font-size: 10px;
    }

    50% {
        font-size: 10.5px
    }

    100% {
        font-size: 10px
    }
}

@media (prefers-reduced-motion: no-preference) {
    .miningStatus__alert {
        animation: pulse 1s infinite;
    }
}

.status {
    padding: 4px 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
    margin: 0 auto;
}

.online {
    background: rgba(85, 192, 0, 0.2);
    color: #55C000;
}

.offline {
    background: rgba(0, 151, 236, 0.2);
    color: var(--primary-color);
}


.slider {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sliderNavigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 40px;
    color: #999999;
}

.slider_arrow {
    height: 40px;
    width: 40px;
    background: rgb(153, 153, 153, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.slider_arrow svg {
    transform: translateX(-20%);
}

.coin_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

}

.coin_title {
    font-size: 18px;
}

.table {
    background: rgba(255, 255, 255, 0.1);
    margin: 40px 20px 20px;
    padding: 0 0 8px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* gap: 8px; */

    overflow: hidden;
}

.tableHeader {
    background: rgba(255, 255, 255, 0.1);
}

.tableLine {
    display: flex;
    font-size: 12px;
    gap: 12px;
    border-bottom: 1px solid rgba(153, 153, 153, 0.1);
    padding: 6px 10px;
}

.tableLine:last-child {
    border: 0;
}

.table .tableLine .tableLine__title {
    flex: 2;
    /* padding: 6px 4px; */

}

.table .tableLine .tableLine__value {
    flex: 1;
    overflow: hidden;
    /* padding: 6px 4px; */
}

.button {
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    height: fit-content;

    padding: 14px 32px;
    border-radius: 64px;

}

.buttons_line {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    gap: 12px;
    align-items: center;
}

.infobutton {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: 2px solid #0097ec; */
    background: rgb(0, 151, 236, 0.2);
    color: #0097ec;
    font-weight: 600;
}

.coin_desc {
    font-size: 12px;
    padding: 8px 20px;
    margin: 20px 0;
    text-align: center;
    color: #cdcdcd;
    line-height: 135%;
}

.link_desc {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #0097ec;
    background: rgba(0, 151, 236, 0.1);
    width: fit-content;
    padding: 4px 12px;
    margin-top: 8px;
    margin: 8px auto 0;
}


.status_update {
    display: flex;
    gap: 6px;
    width: fit-content;
    margin: 0 auto;
    /* background: rgba(255, 255, 255, 0.05); */

    padding: 4px 8px;
    border-radius: 6px;
    align-items: center;
    /* background: linear-gradient(to right, #175c0f, rgba(208, 255, 183, 0)); */
    /* background: linear-gradient(to right, rgba(208, 255, 183, 0), rgba(23, 92, 15, 0.6), rgba(208, 255, 183, 0)); */

    /* background: linear-gradient(to right, #5c0f0f, #8f1d1d, #ae2b2b, #5c0f0f); */



    overflow: hidden;
}

.status_online_update {
    background: linear-gradient(90deg,
            rgba(39, 174, 96, 0) 0%,
            rgba(0, 75, 31, 0.48) 50%,
            rgba(39, 174, 96, 0) 100%);
    background-size: 200% 100%;
    animation: slideHorizontal 3s ease infinite;
}

.status_offline_update {
    background: linear-gradient(90deg,
            rgba(174, 39, 39, 0) 0%,
            rgba(75, 24, 0, 0.48) 70%,
            rgba(174, 39, 39, 0) 100%);
    background-size: 200% 100%;
    animation: slideHorizontal 3s ease infinite;
}

@keyframes slideHorizontal {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.mining_update {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.header_update {
    font-size: 12px;
    color: rgb(236, 236, 236);
    display: flex;
    gap: 4px;
}

.buttons_update {
    display: flex;
    gap: 5px;
}

.button_update {
    font-size: 10px;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
    background: #1f1f1f;
    font-weight: 500;
    color: rgb(236, 236, 236);
}

.button_settings {
    color: #0097ec;
    background: #0097ec3b;
}

.online_update {
    background: rgba(85, 192, 0, 0.2);
    color: #55C000;
}

.offline_update {
    background: rgba(75, 24, 0, 1);
    color: rgb(233, 163, 163);
}



@keyframes colorShift {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}




.green_cyber_outline {
    background-color: rgba(10, 55, 28, 0.2);
    position: relative;
    overflow: hidden;
    border-radius: 12px;

}

.green_cyber_outline::before,
.green_cyber_outline::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1px;
    background: linear-gradient(45deg,
            rgba(39, 174, 96, 0) 0%,
            rgba(46, 204, 113, 0.8) 50%,
            rgba(39, 174, 96, 0) 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    animation: cyberOutline 4s linear infinite;
}

.green_cyber_outline::after {
    animation-delay: -2s;
}

@keyframes cyberOutline {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 200% 0%;
    }
}






/* Test */
.status_update {
    border: 1px solid #ffffff12;
    padding: 8px 14px;
    border-radius: 12px;

}

/* .mining_update{
    flex-direction: row;
    gap: 12px;
}

.buttons_update{
    flex-direction: column;
}

.header_update{
    display: flex;
    flex-direction: column;
    justify-content: center;
}





.status_update {
    border: 1px solid #ffffff12;
    padding: 8px 16px;
    border-radius: 12px;
}






.status_update {
    border: 1px solid #ffffff12;
    padding: 8px 14px;
    border-radius: 12px;
    background: #0d0d0d;
} */


.header_update{
    justify-content: center;
}