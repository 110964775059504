.user {
  background: var(--bg-secondary-color);
  padding: 10px 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  overflow: hidden;
}

.user_gold {
  /* background: linear-gradient(90deg, rgba(255, 200, 0, 0.8) 73.36%, rgb(28, 28, 30) 100%); */
  /* background: linear-gradient(90deg,rgba(218, 165, 32, 0.8) 25%,rgb(240, 191, 67) 100%); */
  background: linear-gradient(90deg, rgba(218, 165, 32, 0.8) 25%, rgba(240, 191, 67, 0.1) 120%);
  /* background: linear-gradient(90deg,rgb(255, 200, 0, 0.8),rgba(255, 222, 34, 0.792), rgb(255, 200, 0, 0.8)); */
  /* background-size: 180% 180%; */
  /* animation: gradient-animation 5s ease infinite; */

  border: 1px solid #DCBD32;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.user_silver {
  /* background: linear-gradient(90deg, rgba(195, 195, 195, 0.7) 73.41%, var(--bg-secondary-color) 98.47%); */
  background: linear-gradient(90deg, rgba(195, 195, 195, 0.7) 25%, var(--bg-secondary-color) 95%);
  /* background-size: 180% 180%; */
  /* animation: gradient-animation 5s ease infinite; */


  /* background: #1F1F21; */
  border: 1px solid #CCCCCC;
}

.user_bronze {
  /* background: linear-gradient(90deg, rgba(205, 127, 50, 0.6) 76.77%, var(--bg-secondary-color) 98.47%); */
  background: linear-gradient(90deg, rgba(205, 127, 50, 0.6) 25%, var(--bg-secondary-color) 90%);
  /* background-size: 180% 180%; */
  /* animation: gradient-animation 5s ease infinite; */

  border: 1px solid #CF8D55;
}


/* 
background: linear-gradient(45deg, oklab(82.7% 0.009 0.146), oklab(75.2% 0.015 0.146));
background: linear-gradient(45deg, oklab(94.6% 0 0), oklab(79.8% 0 0));
background: linear-gradient(45deg, oklab(38.1% 0.055 0.072), oklab(65.3% 0.107 0.116)); 
*/

.user_index {
  font-size: 11px;
  /* width: 24px; */
  text-align: left;
  flex-shrink: 0;
}

.prize {
  font-size: 20px;
  display: flex;
  justify-content: center;
  font-weight: 700;
}



.user__name {
  font-size: 12px;
}

.user__info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user__dataLine {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.user__balance {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #FFE03B;
  font-size: 10px;

}

.user__ghs {
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgb(21, 70, 102, 0.8);
  /* background: #15466678; */
  color: var(--primary-color);
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
}

.filtersButton {
  font-weight: 400;
  font-size: 16px;

  color: #fff;
  gap: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  margin-top: 6px;
}






.balanceTop {
  font-weight: 600;
  color: #ffffff;
}

.nameTop {
  font-weight: 600;
  color: #ffffff;
}


.mining_coin{
  /* background: #CF8D55; */
  background: rgba(255, 255, 255, 0.05);
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 25%;
  clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 0% 100%);

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  padding: 8px;
}