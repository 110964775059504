@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Heavy.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-HeavyItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-SemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-Ultralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text-UltralightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}




html {
  max-height: 100vh;
  height: 100vh;

  overflow: hidden;
}

body {
  max-height: 100vh;
  height: 100vh;

  overflow: hidden;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
  /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
  /* sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
  /* background: #060606; */
  color: #fff;
}


* {
  font-family: 'SF Pro Text', sans-serif;
}


/* typographics */
.text_style_description {
  font-size: 12px;
  color: #b4b4b4;
  line-height: 135%;
}

/* 
.animation-container {
  display: relative;
  width: 135px;
  height: 200px;
}

.y-axis-container {
  animation: bounce 2.6s infinite ease-in-out;
}

.shadow {
  animation: scaling 2.6s infinite ease-in-out;
}

.coin {
  position: absolute;
  height: 200px;
  left: 0;
  width: 135px;
  overflow: hidden;
  object-fit: contain;
  object-position: center;
}






.flash {
  z-index: 200;
  position: absolute;
  width: 15px;
  height: 15px;
  background: white;
  top: 60px;
  right: 60px;
  animation: flash 7s infinite;
}





@keyframes bounce {
  20% {
    animation-timing-function: ease-out;
    transform: translateY(-60px);
  }

  50% {
    animation-timing-function: ease-out;
    transform: translateY(-80px);
  }
}

@keyframes scaling {
  20% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(0.5);
  }
}

@keyframes flash {
  0% {
    transform: rotate(0deg) scale(0);
  }

  8% {
    transform: rotate(0deg) scale(0);
  }

  10% {
    transform: rotate(150deg) scale(1.8);
  }

  15% {
    transform: rotate(45deg) scale(0);
  }

  100% {
    transform: rotate(45deg) scale(0);
  }
}


@keyframes shine {
  20% {
    transform: rotate(25deg) translateY(400px);
  }

  100% {
    transform: rotate(25deg) translateY(400px);
  }
} */


.m_message {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.mHeader {
  font-size: 22px;
  text-align: center;
  margin-bottom: 12px;
}


.mDesc {
  font-size: 14px;
  color: #b4b4b4;
  line-height: 140%;
  text-align: center;
}

.mGif {
  width: 30vw;
  height: 30vw;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-120%) translateX(-50%);
}


.pageOpen {
  transform: translateY(5vh);
  opacity: 0;

  /* Применение анимации */
  animation: pageOpen 0.5s ease-out forwards;
}

@keyframes pageOpen {
  to {
    transform: translateY(0);
    opacity: 1;
    position: relative;
  }
}