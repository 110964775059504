.mainContainer {
    background: linear-gradient(178deg, rgba(3, 149, 235, 0.15) 0%, rgba(0, 0, 0, 0) 25%);
    min-height: 100vh;
    max-height: 100vh;
}

.container {
    padding: 12px 16px 200px;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    position: relative;
    z-index: 0;

    display: flex;
    flex-direction: column;
}

.container::-webkit-scrollbar {
    display: none;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gif {
    max-width: 30vw;
    margin-top: 20px;
    min-width: 30vw;
    min-height: 30vw;
}

.pageHeader {
    font-weight: 500;
    font-size: 24px;
    line-height: 135%;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 10px;
}

.usersTable {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 20px;
}

.user {
    background: var(--bg-secondary-color);
    padding: 12px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.user__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    background: rgba(0, 151, 236, 0.1);

}

.user__name {
    font-size: 16px;
}

.user__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.user__dataLine {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.user__balance {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #FFE03B;

}

.user__ghs {
    display: flex;
    align-items: center;
    gap: 4px;
    background: #0095ec5c;
    color: var(--primary-color);
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 600;
}

.filtersButton {
    font-weight: 400;
    font-size: 16px;

    color: #fff;
    gap: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    margin-top: 6px;
}

.personCard {
    margin-top: 20px;
}

.loader {
    border: 2px solid rgba(255, 255, 255, 0);
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}








.podium_container {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin: 0 auto;
}

/* Подиумы */
.podium {
    position: absolute;
    bottom: 0;
}

.podium_first {
    left: 25%;
    width: 25%;
    height: 100px;
    background: linear-gradient(180deg, #FFB700 0%, #BA8A0E 100%);
    z-index: 3;
}

.podium_second {
    left: 0;
    width: 25%;
    height: 75px;
    background: linear-gradient(180deg, #898989 0%, #232323 100%);
    z-index: 2;
}

.podium_third {
    left: 50%;
    width: 25%;
    height: 50px;
    background: linear-gradient(180deg, #BF7025 0%, #593411 100%);
    z-index: 3;
}

/* 3D эффект для подиумов - только верхняя грань */
.podium::before {
    content: "";
    position: absolute;
    top: -10px;
    right: -20px;
    width: 20px;
    height: 100%;
    /* background: linear-gradient(180deg, #666666 0%, #474747 100%); */
    transform: skewY(-45deg);
    transform-origin: top;
}

/* Верхняя часть подиума */
.podium_top {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;

    /* transform: skewX(-45deg); */
    transform-origin: bottom left;
}

.podium_first .podium_top {
    background: linear-gradient(90deg, #916800 0%, #FFB700 100%);
    clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%);

}

.podium_second .podium_top {
    background: linear-gradient(90deg, #7C7C7C 0%, #7C7C7C 100%);
    clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);

}

.podium_third .podium_top {
    background: linear-gradient(90deg, #AF6722 0%, #AF6722 100%);
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 0% 100%);

}

/* Номера мест */
.number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    font-weight: bold;
    color: white;
    opacity: 0.9;
}

/* Игроки */
.player {
    position: absolute;
    color: white;
}

.player_first {
    left: 25%;
    top: 0;
    text-align: center;

}

.player_second {
    left: 0;
    top: 0;
    text-align: center;

}

.player_third {
    left: 50%;
    top: 0;
    text-align: center;

}

.player_name {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}

.player_score {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.star {
    color: #9370db;
    margin-right: 5px;
}

.score {
    font-size: 24px;
    color: #999;
}