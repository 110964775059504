.modalOverlay {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: flex-end;
    padding: 20px 16px;
}

.modalOverlay:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.modal {
    background: #121212;
    width: 100%;
    padding: 14px 16px 8px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    transition: 0.3s all ease-in-out;
    animation: slideUp 0.5s ease-out forwards;

}

.infoButton {
    border: 1px solid rgba(255, 255, 255, 0.4);
    width: 24px;
    height: 24px;
    border-radius: 24px;
    font-size: 12px;
    font-weight: 600;

    position: absolute;

    right: 20px;
    top: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 0.4);
}

.modal .title {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
    color: #b4b4b4;
}

.modal .description {
    font-size: 12px;
    text-align: center;
    color: #b4b4b4;
}

.modal .buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    padding: 0 10%;
    /* background: #ef4444; */
    width: 100%;
    margin-bottom: 14px;
}

.modal .buttons .modalButton {
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;

    flex-shrink: 0;
    flex: 1;

    padding: 12px 8px;
}

.modal .buttons .modalButton.close {
    display: flex;
    background: #1c1c1e;
}

.modal .lbx {
    width: 50%;
}

.infoCards {
    display: flex;
    flex-direction: column;

    gap: 10px;


    overflow-y: scroll;
    padding-bottom: 40px;
    padding-top: 20px;
}

.infoCards__container {
    position: relative;
    width: 100%;
    display: flex;

    max-height: 280px;
    flex-direction: column;
}

.infoCards__container::after {
    content: '';
    width: 100%;
    height: 30px;
    background: linear-gradient(360deg, #121212, transparent);
    ;
    position: absolute;
    bottom: 0;
    left: 0;

}

.infoCards__container::before {
    content: '';
    width: 100%;
    height: 30px;
    background: linear-gradient(180deg, #121212, transparent);
    ;
    position: absolute;
    bottom: 0;
    top: 0;

}

.infoCards::-webkit-scrollbar {
    display: none;
}

.card {
    display: flex;
    /* background: #E500FF; */
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;

}


.card.coin {
    /* color: #0097ec;
    background: rgb(0, 151, 236, 0.05); */
}

.card .body {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    min-height: 50px;
}

.percents {
    font-size: 14px;
    font-weight: 500;
}

.percents span {
    font-size: 12px;
    font-weight: 500;
    color: #b4b4b4;
}


.premiumIconSVG {
    color: #0097ec;
}


















.pageContainer {
    /* Соответствует min-h-screen */
    width: 100%;
    padding: 30px 0 12px;
}

.motionContainer {
    width: 100%;

    /* Соответствует max-w-4xl */
}

.contentBox {
    /* background-color: #1f2937;  */
    /* border: 1px solid #374151;  */

    /* Соответствует p-4 */
    position: relative;
}

.spinnerWrapper {
    position: relative;

    /* Соответствует mb-8 */
}

.spinnerIndicator {
    position: absolute;
    left: 50%;
    top: -0.5rem;
    /* Соответствует -top-4 (если 1rem = 16px, -4 * 0.25rem = -1rem) */
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    /* Соответствует border-l-[20px] border-l-transparent */
    border-right: 10px solid transparent;
    /* Соответствует border-r-[20px] border-r-transparent */
    border-top: 10px solid #ef4444;
    /* Tailwind border-t-red-500 */
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    /* Соответствует mt-8 */
}

.winBox {
    margin-top: 2rem;
    /* Соответствует mt-8 */
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.winContent {
    /* background-color: #1f2937;  */
    /* border: 1px solid #374151;  */
    padding: 1rem;
    /* Соответствует p-4 */
}

.winTitle {
    color: #ffffff;
    /* Tailwind text-white */
    font-size: 1.25rem;
    /* Соответствует text-xl */
    font-weight: 700;
    /* Соответствует font-bold */
    margin-bottom: 1rem;
    /* Соответствует mb-4 */
    text-align: center;
    /* Соответствует text-center */
}

.winItem {
    display: flex;
    justify-content: center;
}



.toncoin {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-weight: 500;
    font-size: 52px;
    gap: 6px;
}

.modal.closing {
    animation: slideDown 0.5s ease-out forwards;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(100%);
        opacity: 0;
    }
}


.openLootboxTon {
    /* Точка трансформации — низ, чтобы подпрыгивание выглядело естественнее.
       Можно поставить center center, если нужно другое поведение */
    transform-origin: center bottom;

    /* Запускаем анимацию:
          - bounceSquash — название анимации
          - 1.2s — длительность
          - ease-in-out — сглаживание
          - infinite — бесконечный повтор
       */
    animation: bounceSquash 1.2s ease-in-out infinite;
}

body {
    background: #000;
}

@keyframes bounceSquash {
    0% {
        transform: translateY(0) scale(1);
    }

    20% {
        /* Немного поднимаем вверх и растягиваем по X, сжимая по Y */
        transform: translateY(-15px) scaleX(1.1) scaleY(0.9);
    }

    40% {
        /* Возвращаемся к Y=0, но теперь сжимаем по X и растягиваем по Y */
        transform: translateY(0) scaleX(0.9) scaleY(1.1);
    }

    60% {
        /* Слегка поднимаемся ещё раз */
        transform: translateY(-8px) scale(1);
    }

    80% {
        /* Небольшое «завершение» эффекта */
        transform: translateY(0) scaleX(1.05) scaleY(0.95);
    }

    100% {
        /* Возврат к исходному состоянию */
        transform: translateY(0) scale(1);
    }
}

.power-boost {
    /* Задаём размер, можно менять на нужный */
    width: 150px;
    height: auto;

}